<template>
	<div id="statement">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>基础配置</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">报表中心</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="u-f-right head">
				<div class="main-tab-bars u-f-item">
					<div class="main-tab-item" :class="{ active: tabIndex == 0 }">直观视图</div>
				</div>
			</div>
			<template v-if="tabIndex == 0">
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<div class="main-item">
							<div class="u-f-item u-f-jsb">
								<div class="title">教师人数</div>
								<div style="padding-right: 15px;color: #007AFF;cursor: pointer;" @click="more('/teacherlist')">更多</div>
							</div>
							<div class="u-f-justify" style="height: 300px;">
								<div style="margin-right: 60px;">
									<Progress strokeColor="#2D8CF0" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="sex['1']?sex['1']/(sex['1']+sex['2'])*100:0">
										<div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
											{{sex['1']}}
											<span style="font-size: 12px;">人</span>
										</div>
										<template v-slot:footer>
											<div class="u-f-justify" style="color: #94A3AB;">男</div>
										</template>
									</Progress>
								</div>
								<div>
									<Progress strokeColor="#FF7A65" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="sex['2']?sex['2']/(sex['1']+sex['2'])*100:0">
										<div style="font-size:26px;color: #FF7A65;font-weight: 100;">
											{{sex['2']}}
											<span style="font-size: 12px;">人</span>
										</div>
										<template v-slot:footer>
											<div class="u-f-justify" style="color: #94A3AB;">女</div>
										</template>
									</Progress>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<div class="main-item">
							<div class="u-f-item u-f-jsb">
								<div class="title">学生人数</div>
								<div style="padding-right: 15px;color: #007AFF;cursor: pointer;" @click="more('/studentList')">更多</div>
							</div>
							<div class="u-f-justify" style="height: 300px;">
								<div style="margin-right: 60px;" v-if="dataInfo.sex">
									<Progress strokeColor="#2D8CF0" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="dataInfo.sex['1']?dataInfo.sex['1']/(dataInfo.sex['1']+dataInfo.sex['2'])*100:0">
										<div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
											{{dataInfo.sex['1']}}
											<span style="font-size: 12px;">人</span>
										</div>
										<template v-slot:footer>
											<div class="u-f-justify" style="color: #94A3AB;">男生</div>
										</template>
									</Progress>
								</div>
								<div v-if="dataInfo.sex">
									<Progress strokeColor="#FF7A65" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="dataInfo.sex['2']?dataInfo.sex['2']/(dataInfo.sex['1']+dataInfo.sex['2'])*100:0">
										<div style="font-size:26px;color: #FF7A65;font-weight: 100;">
											{{dataInfo.sex['2']}}
											<span style="font-size: 12px;">人</span>
										</div>
										<template v-slot:footer>
											<div class="u-f-justify" style="color: #94A3AB;">女生</div>
										</template>
									</Progress>
								</div>
							</div>
						</div>
					</el-col>

					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<div class="main-item">
							<div class="u-f-item u-f-jsb">
								<div class="title">学生请假</div>
								<div style="padding-right: 15px;color: #007AFF;cursor: pointer;" @click="more('/studentCheck')">更多</div>
							</div>
							<div class="u-f-justify" style="height: 300px;">
								<div style="margin-right: 60px;">
									<Progress strokeColor="#2D8CF0" :transitionDuration="100" :radius="60" :strokeWidth="15" :value="info.month_student">
										<div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
											{{info.month_student}}
											<span style="font-size: 12px;">人</span>
										</div>
										<template v-slot:footer>
											<div class="u-f-justify" style="color: #94A3AB;">本月</div>
										</template>
									</Progress>
								</div>
								<div>
									<Progress strokeColor="#FF7A65" :transitionDuration="100" :radius="60" :strokeWidth="15" :value="info.day_student">
										<div style="font-size:26px;color: #FF7A65;font-weight: 100;">
											{{info.day_student}}
											<span style="font-size: 12px;">人</span>
										</div>
										<template v-slot:footer>
											<div class="u-f-justify" style="color: #94A3AB;">今日</div>
										</template>
									</Progress>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<div class="main-item">
							<div class="u-f-item u-f-jsb">
								<div class="title">教师请假</div>
								<div style="padding-right: 15px;color: #007AFF;cursor: pointer;" @click="more('/teacherCheck')">更多</div>
							</div>
							<div class="u-f-justify" style="height: 300px;">
								<div style="margin-right: 60px;">
									<Progress strokeColor="#2D8CF0" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="info.month_teacher">
										<div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
											{{info.month_teacher}}
											<span style="font-size: 12px;">人</span>
										</div>
										<template v-slot:footer>
											<div class="u-f-justify" style="color: #94A3AB;">本月</div>
										</template>
									</Progress>
								</div>
								<div>
									<Progress strokeColor="#FF7A65" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="info.day_teacher">
										<div style="font-size:26px;color: #FF7A65;font-weight: 100;">
											{{info.day_teacher}}
											<span style="font-size: 12px;">人</span>
										</div>
										<template v-slot:footer>
											<div class="u-f-justify" style="color: #94A3AB;">今日</div>
										</template>
									</Progress>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</template>
		</index>
	</div>
</template>

<script>
import Progress from 'easy-circular-progress';
import index from '@/components/common/index.vue';
export default {
	components: {
		Progress,
		index
	},
	props: {},
	data() {
		return {
			tabIndex: 0,
			sex: {},
			dataInfo: {},
			info:''
		};
	},
	mounted() {
		this.initData()
		this.getStudentList()
		this.student_total()
	},
	methods: {
		// 学生档案列表
		getStudentList(){
			this.$api.student.studentList({
				year_id: window.localStorage.getItem('year_id'),
				semester_id: window.localStorage.getItem('semester_id')
			}).then(res => {
				if (res.data.code == 1) {
					this.dataInfo = res.data.data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		initData(){
			this.$api.teachers.teachersList({}).then(res => {
				if (res.data.code == 1) {
					this.sex = res.data.data.gender;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		more(url){
			this.$router.push(url)
		},
		student_total(){
			this.$api.student.student_total({}).then(res => {
				if (res.data.code == 1) {
					this.info = res.data.data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		}
	}
};
</script>

<style lang="scss">
#statement {
	.head {
		background-color: #ffffff;
		padding: 8px 20px;
		margin-top: 15px;
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
	}
	.main-item {
		background: #ffffff;
		margin-top: 15px;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
			color: #4e4e4e;
			border-bottom: 1px solid #fafafb;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 15px;
		height: 79.3vh;
		overflow: hidden;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.vue-circular-progress .circle__progress {
		stroke-linecap: square;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 20px;
		.el-radio{
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
	.el-dialog__footer{
		border-top: 1px solid #EEEEEE;
		text-align: center;
	}
	.el-table__column-filter-trigger{
		.el-icon-arrow-down:before{
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
